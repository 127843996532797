import React from 'react';
import { NextSeo } from 'next-seo';
import { Carousel } from 'antd';
import less from './index.less';
import account from '@/common/account/index';
import whichEnvironment from '@/common/utils/whichEnvironment';
import './index.css';

let paasUrl = '';
/* eslint-disable indent */
switch (whichEnvironment()) {
  case 'CODING':
    paasUrl = 'http://localhost:8080';
    break;
  case 'DEV':
    paasUrl = 'https://gateway-test-multi.facethink.com';
    break;
  case 'TEST':
    paasUrl = 'https://gateway-test-multi.facethink.com';
    break;
  case 'RELEASE':
    paasUrl = 'https://openai.100tal.com';
    break;
  default:
    break;
}

function gotoCooperationPage() {
  window.open('/cooperation');
}

function gotoProductPage(name) {
  window.location.href = `/product/${name}`;
}

function gotoSolutionPage(name) {
  window.location.href = `/solution?name=${name}`;
}

function handleFreeTasteBtnClick() {
  if (account.hasLoggedin()) {
    window.open(paasUrl + '/console/manage/homepage');
  } else {
    window.open('/account/login');
  }
}

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSolutionType: 0,
      isShowingMoreSolutions: false,
      isShowingMoreCap0: false,
      isShowingMoreCap2: false,
      isShowingMoreCap4: false,
      show: {
        opacity: 0
      }
    };
    this.refBannerCarousel = React.createRef();
    this.refCooperationCarousel = React.createRef();
    this.refSolutionCarousel = React.createRef();
    this.delayGotoCarouselNext = this.delayGotoCarouselNext.bind(this);
    this.delayGotoCooperationCarouselNext =
      this.delayGotoCooperationCarouselNext.bind(this);
    this.setActiveSolutionStep = this.setActiveSolutionStep.bind(this);
    this.toggleShowingMoreSolutions =
      this.toggleShowingMoreSolutions.bind(this);
    this.toggleShowingMoreCaps = this.toggleShowingMoreCaps.bind(this);
    this.carouselTimeoutId = null;
    this.cooperationCarouselTimeoutId = null;
    this.handlePrev = this.handlePrev.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleOut = this.handleOut.bind(this);
  }

  delayGotoCarouselNext() {
    clearTimeout(this.carouselTimeoutId);
    this.carouselTimeoutId = setTimeout(() => {
      if (!this.refBannerCarousel.current) {
        return;
      }
      this.refBannerCarousel.current.next();
    }, 5000);
  }

  delayGotoCooperationCarouselNext() {
    clearTimeout(this.cooperationCarouselTimeoutId);
    this.cooperationCarouselTimeoutId = setTimeout(() => {
      if (!this.refCooperationCarousel.current) {
        return;
      }
      this.refCooperationCarousel.current.next();
    }, 5000);
  }

  componentDidMount() {
    this.delayGotoCarouselNext();
    this.delayGotoCooperationCarouselNext();
  }

  setActiveSolutionStep(num) {
    this.setState({
      activeSolutionType: num
    });
    this.refSolutionCarousel.current.goTo(num, true);
    if (num !== 0) {
      this.setState({
        isShowingMoreSolutions: false
      });
    }
  }

  toggleShowingMoreSolutions() {
    this.setState((lastState) => ({
      isShowingMoreSolutions: !lastState.isShowingMoreSolutions
    }));
  }

  toggleShowingMoreCaps(id) {
    this.setState((lastState) => {
      let newState = {};
      newState[`isShowingMoreCap${id}`] = !lastState[`isShowingMoreCap${id}`];
      return newState;
    });
  }

  handlePrev = () => {
    this.refBannerCarousel.current.prev();
  };

  handleNext = () => {
    this.refBannerCarousel.current.next();
  };

  handleShow = () => {
    this.setState({
      show: {
        opacity: 1
      }
    });
  };

  handleOut = () => {
    this.setState({
      show: {
        opacity: 0
      }
    });
  };

  render() {
    return (
      <div className={less.container}>
        <NextSeo title="好未来AI开放平台" />
        <div
          className={less.bannerBox}
          id="bannerBox"
          onMouseEnter={this.handleShow}
          onMouseLeave={this.handleOut}
        >
          <div
            className={less.left}
            onClick={this.handlePrev}
            style={this.state.show}
          >
            <img
              src="https://openplantform.oss-cn-beijing.aliyuncs.com/index/L.png?OSSAccessKeyId=LTAI4FvBYgUM9wyFK4wa9nvy&Expires=5183400482&Signature=vZZAfGQQcFrVUU%2BRZFRldyD30mU%3D"
              alt="左"
              width="26px"
              height="47px"
            />
          </div>
          <div
            className={less.right}
            onClick={this.handleNext}
            style={this.state.show}
          >
            <img
              src="https://openplantform.oss-cn-beijing.aliyuncs.com/index/R.png?OSSAccessKeyId=LTAI4FvBYgUM9wyFK4wa9nvy&Expires=5183400570&Signature=xGioJ1mvwXlT%2Fx6ZF5JPb6%2B3e4Q%3D"
              alt="右"
              width="26px"
              height="47px"
            />
          </div>
          <Carousel
            ref={this.refBannerCarousel}
            dotPosition="bottom"
            afterChange={this.delayGotoCarouselNext}
          >
            <div className={less.bannerItem + ' ' + less.bannerItem3}>
              <div className={less.title}>拍照搜题解决方案</div>
              <div className={less.description}>
                拍照搜索题目讲解视频，关联知识点视频及相似题
              </div>
              <div className={less.btnToDetail}>
                <span onClick={() => gotoSolutionPage('photo-search')}>
                  查看详情
                </span>
              </div>
            </div>
            <div className={less.bannerItem + ' ' + less.bannerItem5}>
              <div className={less.empty}> </div>
              <div className={less.description}>
                <span style={{ fontWeight: 800, fontSize: '28px' }}>
                  HawkEye{' '}
                </span>
                教育通用OCR：
                高精度文字公式一体化识别，支持拍照搜题、拍照批改、内容转录等多个场景
              </div>
              <div className={less.btnToDetail}>
                <span onClick={() => gotoSolutionPage('hawk-eye')}>
                  查看详情
                </span>
              </div>
            </div>
            <div className={less.bannerItem + ' ' + less.bannerItem4}>
              <div className={less.title + ' ' + less.techTitle}>
                <sapn>智慧教育</sapn>
              </div>
              <div className={less.description + ' ' + less.techDescription}>
                <span>国家新一代人工智能开放创新平台</span>
              </div>
              <div className={less.btnToDetail}>
                <span onClick={() => (window.location.href = '/news-info')}>
                  查看详情
                </span>
              </div>
            </div>
            <div className={less.bannerItem + ' ' + less.bannerItem1}>
              <div className={less.title}>教学质量评估解决方案</div>
              <div className={less.description}>
                六大学习行为分级体系，精准评价课堂学习效果
              </div>
              <div className={less.btnToDetail}>
                <span onClick={() => gotoSolutionPage('s-tqa')}>查看详情</span>
              </div>
            </div>
            <div className={less.bannerItem + ' ' + less.bannerItem2}>
              <div className={less.title}>中文口语表达能力评测重磅发布</div>
              <div className={less.description}>
                在线评测口语表达能力，专业多维度评测结果实时反馈
              </div>
              <div className={less.btnToDetail}>
                <span onClick={() => gotoSolutionPage('s-csa')}>查看详情</span>
              </div>
            </div>
            <div className={less.bannerItem + ' ' + less.bannerItem3}>
              <div className={less.title}>试卷拆录解决方案</div>
              <div className={less.description}>
                精准试卷拆解、识别，解锁试卷录入新方式
              </div>
              <div className={less.btnToDetail}>
                <span onClick={() => gotoSolutionPage('s-tpr')}>查看详情</span>
              </div>
            </div>
          </Carousel>
        </div>
        <div className={less.solutionsBox}>
          <div className={less.title}>好未来教育+AI解决方案</div>
          <div className={less.solutionTypesBox}>
            <div
              className={
                less.solutionType +
                ' ' +
                (this.state.activeSolutionType === 0 && less.active)
              }
              onClick={() => this.setActiveSolutionStep(0)}
            >
              拍照搜题
              <span className={less.bottomLine} />
            </div>
            <div
              className={
                less.solutionType +
                ' ' +
                (this.state.activeSolutionType === 1 && less.active)
              }
              onClick={() => this.setActiveSolutionStep(1)}
            >
              语音评测
              <span className={less.bottomLine} />
            </div>
            <div
              className={
                less.solutionType +
                ' ' +
                (this.state.activeSolutionType === 2 && less.active)
              }
              onClick={() => this.setActiveSolutionStep(2)}
            >
              教育通用OCR
              <span className={less.bottomLine} />
            </div>
            <div
              className={
                less.solutionType +
                ' ' +
                (this.state.activeSolutionType === 3 && less.active)
              }
              onClick={() => this.setActiveSolutionStep(3)}
            >
              教学管理
              <span className={less.bottomLine} />
            </div>
            <div
              className={
                less.solutionType +
                ' ' +
                (this.state.activeSolutionType === 4 && less.active)
              }
              onClick={() => this.setActiveSolutionStep(4)}
            >
              标注系统
              <span className={less.bottomLine} />
            </div>
          </div>
          <Carousel ref={this.refSolutionCarousel} dots={false}>
            <div className={less.solutionDetailCon}>
              <div className={less.solutionDetailBox}>
                <div
                  className={less.detail}
                  onClick={() => gotoSolutionPage('photo-search')}
                >
                  <div className={less.banner1}>
                    <div className={less.title}>拍照搜题解决方案</div>
                  </div>
                  <div className={less.description}>
                    基于教育行业领先的OCR识别技术，识别用户上传的题目图片，精准匹配题库，返回题目答案、解析、讲解视频、相似题及其相关知识点讲解
                  </div>
                  <div className={less.more}>
                    <span className={less.text}>了解更多 &gt;&gt;</span>
                  </div>
                </div>
                <div
                  className={less.detail}
                  onClick={() => gotoSolutionPage('hawk-eye')}
                >
                  <div className={less.banner11}>
                    <div className={less.title}>HawkEye 教育通用OCR</div>
                  </div>
                  <div className={less.description}>
                    基于教育行业内领先的OCR识别技术，实现对手写、印刷、表格、公式等的识别，满足拍照搜题、拍照批改、内容转录等多场景需求
                  </div>
                  <div className={less.more}>
                    <span className={less.text}>了解更多 &gt;&gt;</span>
                  </div>
                </div>
                <div
                  className={less.detail}
                  onClick={() => gotoSolutionPage('s-coc')}
                >
                  <div className={less.banner7}>
                    <div className={less.title}>口算批改解决方案</div>
                  </div>
                  <div className={less.description}>
                    基于行业领先的智能文字识别技术，给学生、家长和老师提供自动批改小学口算题服务
                  </div>
                  <div className={less.more}>
                    <span className={less.text}>了解更多 &gt;&gt;</span>
                  </div>
                </div>
              </div>
            </div>
            <div className={less.solutionDetailCon}>
              <div
                className={less.solutionDetailBox}
                style={{
                  marginBottom: this.state.isShowingMoreSolutions ? '5%' : '0'
                }}
              >
                <div
                  className={less.detail}
                  onClick={() => gotoSolutionPage('s-lci')}
                >
                  <div className={less.banner5}>
                    <div className={less.title}>直播课教学互动解决方案</div>
                  </div>
                  <div className={less.description}>
                    基于人工智能技术对直播课堂问答、语音、手势实时反馈，并对学生学习过程评估，提高直播课堂互动体验
                  </div>
                  <div className={less.more}>
                    <span className={less.text}>了解更多 &gt;&gt;</span>
                  </div>
                </div>
                <div
                  className={less.detail}
                  onClick={() => gotoSolutionPage('s-csa')}
                >
                  <div className={less.banner6}>
                    <div className={less.title}>中文口语表达能力评测</div>
                  </div>
                  <div className={less.description}>
                    结合童音识别和口语表达评价算法，从七大维度综合评估用户的口语表达能力，给出评分和评语建议
                  </div>
                  <div className={less.more}>
                    <span className={less.text}>了解更多 &gt;&gt;</span>
                  </div>
                </div>
                <div
                  className={less.detail}
                  onClick={() => gotoSolutionPage('s-cpr')}
                >
                  <div className={less.banner10}>
                    <div className={less.title}>儿童诗词背诵评测</div>
                  </div>
                  <div className={less.description}>
                    基于儿童语音识别、语音评测等AI技术，评测儿童在诗词背诵的能力，并给出评分及错误提示等信息
                  </div>
                  <div className={less.more}>
                    <span className={less.text}>了解更多 &gt;&gt;</span>
                  </div>
                </div>
              </div>
              <div
                className={less.solutionDetailBox}
                style={{
                  display: this.state.isShowingMoreSolutions ? 'flex' : 'none'
                }}
              >
                <div
                  className={less.detail}
                  onClick={() => gotoSolutionPage('s-sepe')}
                >
                  <div className={less.banner9}>
                    <div className={less.title}>英文口语发音评测</div>
                  </div>
                  <div className={less.description}>
                    基于儿童英文识别、评测等AI技术，通过真实教育场景下的儿童语言发声数据，提供儿童英文发音、流利度等评测
                  </div>
                  <div className={less.more}>
                    <span className={less.text}>了解更多 &gt;&gt;</span>
                  </div>
                </div>
                <div
                  className={less.detail}
                  onClick={() => gotoSolutionPage('s-mse')}
                >
                  <div className={less.banner8}>
                    <div className={less.title}>普通话口语评测</div>
                  </div>
                  <div className={less.description}>
                    基于儿童语音识别、语音评测等AI技术，评测普通话发音、流利度等信息
                  </div>
                  <div className={less.more}>
                    <span className={less.text}>了解更多 &gt;&gt;</span>
                  </div>
                </div>
                <div className={less.detailMore}>
                  <div>
                    <img
                      src="https://openplantform.oss-cn-beijing.aliyuncs.com/index/sandglass.png?OSSAccessKeyId=LTAI4FvBYgUM9wyFK4wa9nvy&Expires=361582624833&Signature=IcBI8sm20%2F67Y4cxi%2BmCgfMNBUk%3D"
                      className={less.sandglass}
                      alt="sandglass.png"
                    />
                    <div className={less.text}>更多解决方案，敬请期待!</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={less.solutionDetailCon}>
              <div className={less.solutionDetailBox}>
                <div
                  className={less.detail}
                  onClick={() => gotoSolutionPage('s-ocr-common')}
                >
                  <div className={less.banner13}>
                    <div className={less.title}>教育通用OCR</div>
                  </div>
                  <div className={less.description}>
                    可对教育场景的书籍、作业、各科试卷的版面进行分析，输出图、表、标题、文本的位置，并输出文本版块内容的识别结果；
                  </div>
                  <div className={less.more}>
                    <span className={less.text}>了解更多 &gt;&gt;</span>
                  </div>
                </div>
                <div
                  className={less.detail}
                  onClick={() => gotoSolutionPage('s-tpr')}
                >
                  <div className={less.banner1}>
                    <div className={less.title}>试卷拆录解决方案</div>
                  </div>
                  <div className={less.description}>
                    自动将试卷中的每个题目拆解、识别，并对老师的批改痕迹识别和分析，实现自动化拆题和识别
                  </div>
                  <div className={less.more}>
                    <span className={less.text}>了解更多 &gt;&gt;</span>
                  </div>
                </div>
                <div
                  className={less.detail}
                  onClick={() => gotoSolutionPage('s-cas')}
                >
                  <div className={less.banner12}>
                    <div className={less.title}>内容审核解决方案</div>
                  </div>
                  <div className={less.description}>
                    基于先进的AI能力，精准快速的对视频，音频，图片，文本进行内容审核，并支持个性化定制审核条件
                  </div>
                  <div className={less.more}>
                    <span className={less.text}>了解更多 &gt;&gt;</span>
                  </div>
                </div>
              </div>
            </div>
            <div className={less.solutionDetailCon}>
              <div className={less.solutionDetailBox}>
                <div
                  className={less.detail}
                  onClick={() => gotoSolutionPage('s-tqa')}
                >
                  <div className={less.banner3}>
                    <div className={less.title}>教学质量评估解决方案</div>
                  </div>
                  <div className={less.description}>
                    基于人工智能技术挖掘多维客观指标来量化评估教学效果，提供教育场景下的全链路教学质量评估
                  </div>
                  <div className={less.more}>
                    <span className={less.text}>了解更多 &gt;&gt;</span>
                  </div>
                </div>
                <div
                  className={less.detail}
                  onClick={() => gotoSolutionPage('s-tbm')}
                >
                  <div className={less.banner4}>
                    <div className={less.title}>课堂异常行为监测</div>
                  </div>
                  <div className={less.description}>
                    基于语音、图像等AI技术来检测课堂中的异常行为，控制课中风险
                  </div>
                  <div className={less.more}>
                    <span className={less.text}>了解更多 &gt;&gt;</span>
                  </div>
                </div>
                <div
                  className={less.detail}
                  onClick={() => gotoSolutionPage('s-tgc')}
                >
                  <div className={less.banner2}>
                    <div className={less.title}>授课质量分析解决方案</div>
                  </div>
                  <div className={less.description}>
                    基于AI技术对老师授课过程进行分析，为老师备课、练课、磨课、讲课等提供AI科学评测，帮助老师不断优化课堂效果
                  </div>
                  <div className={less.more}>
                    <span className={less.text}>了解更多 &gt;&gt;</span>
                  </div>
                </div>
              </div>
            </div>
            <div className={less.solutionDetailCon}>
              <div className={less.solutionDetailBox}>
                <div
                  className={less.detail}
                  onClick={() => gotoSolutionPage('s-vegas')}
                >
                  <div className={less.banner1}>
                    <div className={less.title}>vegas标注系统</div>
                  </div>
                  <div className={less.description}>
                    基于行业领先的AI能力，助力数据标注，让数据标注更准，更快，更好
                  </div>
                  <div className={less.more}>
                    <span className={less.text}>了解更多 &gt;&gt;</span>
                  </div>
                </div>
                <div className={less.detailMore}>
                  <div>
                    <img
                      src="https://openplantform.oss-cn-beijing.aliyuncs.com/index/sandglass.png?OSSAccessKeyId=LTAI4FvBYgUM9wyFK4wa9nvy&Expires=361582624833&Signature=IcBI8sm20%2F67Y4cxi%2BmCgfMNBUk%3D"
                      className={less.sandglass}
                      alt="sandglass.png"
                    />
                    <div className={less.text}>更多解决方案，敬请期待!</div>
                  </div>
                </div>
              </div>
            </div>
          </Carousel>
          <div
            className={less.moreSolutionsBtn}
            style={{
              display: this.state.activeSolutionType === 1 ? 'block' : 'none'
            }}
            onClick={this.toggleShowingMoreSolutions}
          >
            {this.state.isShowingMoreSolutions ? '收起' : '更多'}
          </div>
        </div>
        <div className={less.capabilityBox}>
          <div className={less.title}>好未来AI能力，提升教育教学效果</div>
          <div className={less.capabilityGroup}>
            <div>
              <div className={less.type} style={{ marginBottom: '0px' }}>
                <div className={less.title}>教育OCR</div>
                <div className={less.titleUnderline} />
                <div
                  className={less.item}
                  onClick={() => gotoProductPage('ocr-ptr')}
                >
                  印刷识别
                </div>
                <div
                  className={less.item}
                  onClick={() => gotoProductPage('ocr-hr')}
                >
                  手写识别
                </div>
                <div
                  className={less.item}
                  onClick={() => gotoProductPage('ocr-psmhc')}
                >
                  表格识别
                </div>
                <div
                  className={less.item}
                  onClick={() => gotoProductPage('ocr-gs')}
                >
                  公式识别
                </div>
                <div
                  className={less.item}
                  onClick={() => gotoProductPage('ocr-sj')}
                >
                  试卷拆录
                </div>
              </div>
            </div>
            <div>
              <div className={less.type}>
                <div className={less.title}>智能批改</div>
                <div className={less.titleUnderline} />
                <div
                  className={less.item}
                  onClick={() => gotoProductPage('assess-note')}
                >
                  笔记评估
                </div>
                <div
                  className={less.item}
                  onClick={() => gotoProductPage('assess-mindmapping')}
                >
                  思维导图评估
                </div>
              </div>
              <div className={less.type} style={{ marginBottom: '0px' }}>
                <div className={less.title}>图像视频分析</div>
                <div className={less.titleUnderline} />
                <div
                  className={less.item}
                  onClick={() => gotoProductPage('logo-detection')}
                >
                  Logo检测
                </div>
                <div
                  className={less.item}
                  onClick={() => gotoProductPage('content-audit')}
                >
                  内容审核
                </div>
              </div>
            </div>
            <div>
              <div className={less.type}>
                <div className={less.title}>人脸分析</div>
                <div className={less.titleUnderline} />
                <div
                  className={less.item}
                  onClick={() => gotoProductPage('fr-fdaa')}
                >
                  人脸检测
                </div>
                <div
                  className={less.item}
                  onClick={() => gotoProductPage('fr-fs')}
                >
                  人脸搜索
                </div>
              </div>
              <div className={less.type} style={{ marginBottom: '0px' }}>
                <div className={less.title}>人体分析</div>
                <div className={less.titleUnderline} />
                <div
                  className={less.item}
                  onClick={() => gotoProductPage('fr-gr')}
                >
                  手势识别
                </div>
                <div
                  className={less.item}
                  onClick={() => gotoProductPage('vu-lsda')}
                >
                  着装检测
                </div>
              </div>
            </div>
            <div>
              <div className={less.type}>
                <div className={less.title}>语音识别</div>
                <div className={less.titleUnderline} />
                <div
                  className={less.item}
                  onClick={() => gotoProductPage('voice-files-transcription')}
                >
                  语音文件转录
                </div>
                <div
                  className={less.item}
                  onClick={() => gotoProductPage('real-time-voice-identify')}
                >
                  实时语音识别
                </div>
                <div
                  className={less.item}
                  onClick={() => gotoProductPage('one-words-voice-identify')}
                >
                  一句话语音识别
                </div>
              </div>
              <div className={less.type} style={{ marginBottom: '35px' }}>
                <div className={less.title}>语音合成</div>
                <div className={less.titleUnderline} />
                <div
                  className={less.item}
                  onClick={() => gotoProductPage('general-voice-synthetic')}
                >
                  通用语音合成
                </div>
                <div
                  className={less.item}
                  onClick={() =>
                    gotoProductPage('personalized-voice-synthetic')
                  }
                >
                  个性化语音合成
                </div>
              </div>
            </div>
            <div>
              <div className={less.type}>
                <div className={less.title}>语音评测</div>
                <div className={less.titleUnderline} />
                <div
                  className={less.item}
                  onClick={() => gotoProductPage('general-voice-review')}
                >
                  通用语音评测
                </div>
                <div
                  className={less.item}
                  onClick={() => gotoProductPage('make-sentence-review')}
                >
                  中英文造句评测
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={less.strengthBox}>
          <div className={less.title}>我们的优势</div>
          <div className={less.itemsBox}>
            <div className={less.item}>
              <img
                src="https://openplantform.oss-cn-beijing.aliyuncs.com/index/jiaoyu.png?OSSAccessKeyId=LTAI4FvBYgUM9wyFK4wa9nvy&Expires=5184355675&Signature=Rq0SnMn%2BQnS9L0ARoTRFAaAgS80%3D"
                alt="streng0"
                className={less.img}
              />
              <div className={less.textBox}>
                <div className={less.title}>扎根教育行业</div>
                <div className={less.desc}>
                  18年以上教育行业经验，丰富的教育场景，经过真实场景应用检验
                </div>
              </div>
            </div>
            <div className={less.item}>
              <img
                src="https://openplantform.oss-cn-beijing.aliyuncs.com/index/lingxian.png?OSSAccessKeyId=LTAI4FvBYgUM9wyFK4wa9nvy&Expires=5184355761&Signature=4A4K3XvHYPyLfR%2Btt1pxw7E3MXo%3D"
                alt="streng1"
                className={less.img}
              />
              <div className={less.textBox}>
                <div className={less.title}>领先的AI技术</div>
                <div className={less.desc}>
                  合作国内外顶尖高校，优秀的算法团队和研发实力，AI技术行业领先
                </div>
              </div>
            </div>
            <div className={less.item}>
              <img
                src="https://openplantform.oss-cn-beijing.aliyuncs.com/index/gaoxiao.png?OSSAccessKeyId=LTAI4FvBYgUM9wyFK4wa9nvy&Expires=5184355529&Signature=h8ydQJjPcNXHoTB0hXXXh6qEI%2BY%3D"
                alt="streng2"
                className={less.img}
              />
              <div className={less.textBox}>
                <div className={less.title}>高效稳定的服务</div>
                <div className={less.desc}>
                  稳定的平台运营能力，提供高并发、高可用性保障和全方位服务
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={less.cooperationBox}>
          <div className={less.title}>与合作伙伴 共建教育+AI</div>
          <div className={less.carouselBox} id="carouselBox">
            <Carousel
              ref={this.refCooperationCarousel}
              dotPosition="bottom"
              afterChange={this.delayGotoCooperationCarouselNext}
            >
              <div className={less.logosBox + ' ' + less.logosBox1}>
                <div className={less.logos}>
                  <div className={less.logo} />
                  <div className={less.logo} />
                  <div className={less.logo} />
                  <div className={less.logo} />
                </div>
                <div className={less.logos}>
                  <div className={less.logo} />
                  <div className={less.logo} />
                  <div className={less.logo} />
                  <div className={less.logo} />
                </div>
                <div className={less.logos}>
                  <div className={less.logo} />
                  <div className={less.logo} />
                  <div className={less.logo} />
                  <div className={less.logo} />
                </div>
              </div>
              <div className={less.logosBox + ' ' + less.logosBox2}>
                <div className={less.logos}>
                  <div className={less.logo} />
                  <div className={less.logo} />
                  <div className={less.logo} />
                  <div className={less.logo} />
                </div>
                <div className={less.logos}>
                  <div className={less.logo} />
                  <div className={less.logo} />
                  <div className={less.logo} />
                  <div className={less.logo} />
                </div>
                <div className={less.logos}>
                  <div className={less.logo} />
                  <div className={less.logo} />
                  <div className={less.logo} />
                  <div className={less.logo} />
                </div>
              </div>
              <div className={less.logosBox + ' ' + less.logosBox3}>
                <div className={less.logos}>
                  <div className={less.logo} />
                  <div className={less.logo} />
                  <div className={less.logo} />
                  <div className={less.logo} />
                </div>
              </div>
            </Carousel>
          </div>
        </div>
        <div className={less.ai_taste}>
          <p className={less.ai_title}>全线产品持续开放，期待与您合作</p>
          <p className={less.ai_smalltitle}>
            现在注册，免费使用好未来所有开放能力，让你的应用更了解教育
          </p>
          <div className={less.button}>
            <input
              className={less.button1}
              type="button"
              value="免费体验"
              onClick={handleFreeTasteBtnClick}
            />
            <input
              className={less.button2}
              type="button"
              value="合作咨询"
              onClick={gotoCooperationPage}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Index;
